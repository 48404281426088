import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function WhyChoose() {
  return (
    <>

      <Index.Box className="why-choose-section">
        <Index.Box className="container">
          <Index.Box className="section-title-main">
            <Index.motion.div
              animate={{ y: 250, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
              <Index.Typography className="section-title section-title-white">
                Why Choose <span className='section-highlight-text'>Glorion</span>
              </Index.Typography>
              <Index.Typography className="section-title-para">
                Glorion has developed a potent trading platform on the Solana blockchain by fusing community-focused innovation with AI-driven technology.
              </Index.Typography>
            </Index.motion.div>
          </Index.Box>
          <Index.Box className="choose-row">
            <Index.motion.div
              animate={{ y: 100, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
              <Index.Box className="choose-col">
                <Index.Box className="choose-round-main">
                  <Index.Box className="choose-round-fill">
                    <img src={PagesIndex.Svg.chooseIcon1} className="choose-logo" alt="logo" />
                  </Index.Box>
                </Index.Box>
                <Index.Typography className="choose-title">AI-Driven Trading
                </Index.Typography>
                <Index.Typography className="choose-para">Glorion's Swap, Wallet, and Exchange are entirely AI-powered, delivering precision and efficiency in every transaction.
                </Index.Typography>
              </Index.Box>
            </Index.motion.div>

            <Index.motion.div
              animate={{ y: 200, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
              <Index.Box className="choose-col">
                <Index.Box className="choose-round-main">
                  <Index.Box className="choose-round-fill">
                    <img src={PagesIndex.Svg.chooseIcon2} className="choose-logo" alt="logo" />
                  </Index.Box>
                </Index.Box>
                <Index.Typography className="choose-title">Solana Community-Powered</Index.Typography>
                <Index.Typography className="choose-para">Built with the Solana community at its core, Glorion fosters seamless decentralized trading with unmatched speed and low fees. </Index.Typography>
              </Index.Box>
            </Index.motion.div>


            <Index.motion.div
              animate={{ y: 300, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
              <Index.Box className="choose-col">
                <Index.Box className="choose-round-main">
                  <Index.Box className="choose-round-fill">
                    <img src={PagesIndex.Svg.chooseIcon3} className="choose-logo" alt="logo" />
                  </Index.Box>
                </Index.Box>
                <Index.Typography className="choose-title">Comprehensive Ecosystem</Index.Typography>
                <Index.Typography className="choose-para">From token creation to AI-based swaps and wallets, Glorion offers a complete token lifecycle with secure and intuitive tools.
                </Index.Typography>
              </Index.Box>
            </Index.motion.div>

            <Index.motion.div
              animate={{ y: 400, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
              <Index.Box className="choose-col">
                <Index.Box className="choose-round-main">
                  <Index.Box className="choose-round-fill">
                    <img src={PagesIndex.Svg.chooseIcon4} className="choose-logo" alt="logo" />
                  </Index.Box>
                </Index.Box>
                <Index.Typography className="choose-title">Rewards & Incentives                </Index.Typography>
                <Index.Typography className="choose-para">Users benefit from staking, referral programs, and rewards, making trading more profitable and engaging. </Index.Typography>
              </Index.Box>
            </Index.motion.div>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}
