import React from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes as Routess,
} from "react-router-dom";
import { createBrowserHistory } from 'history'

//#region user
import { useSelector } from "react-redux";
import Layout from "../container/user/pages/layout/Layout";
import Home from "../container/user/pages/home/Home";
import BlogList from "../container/user/pages/blog/BlogList";
import BlogDetails from "../container/user/pages/blog/BlogDetails";
import CmsManagement from "../container/user/pages/cmsManagement/CmsManagement";
import ContactUs from "../container/user/pages/contactUs/ContactUs";
//#endregion



const history = createBrowserHistory();
export default function Routes() {

  const login = useSelector((state) => {
    return state.AdminReducer.isAdminLogin
  });
  // const login = true;

  return (
    <BrowserRouter history={history}>
      <Routess>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/blog-list" element={<BlogList />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/cms/:path" element={<CmsManagement />} />
        </Route>
      </Routess>
    </BrowserRouter>
  );
}
