import React, { useState } from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'


export default function HeadlineSlider() {


      const [headlineSlider, setheadlineSlider] = useState({
            headlineSlider: {
                  0: {
                        items: 1,
                        margin: 0,
                  },
                  768: {
                        items: 2,
                        margin: 30,
                  },
                  1024: {
                        items: 2,
                  },
                  1200: {
                        items: 7,
                  }
            }
      })

      return (
            <>


                  <Index.Box className="headline-section">
                        <Index.Box className="marquee">
                              <Index.Box className="marquee-flex">
                                    <Index.Box className="headline-card">
                                          <img src={PagesIndex.Svg.greenLines} className="headline-img" alt="logo" />
                                          <Index.Typography className='headline-title'>AI-Powered Swap</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="headline-card">
                                          <img src={PagesIndex.Svg.whiteLine} className="headline-img" alt="logo" />
                                          <Index.Typography className='headline-title'> Seamless Solana Integration</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="headline-card">
                                          <img src={PagesIndex.Svg.greenLines} className="headline-img" alt="logo" />
                                          <Index.Typography className='headline-title'> Staking & Rewards</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="headline-card">
                                          <img src={PagesIndex.Svg.whiteLine} className="headline-img" alt="logo" />
                                          <Index.Typography className='headline-title'> Decentralized Trading</Index.Typography>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                        {/* <Index.OwlCarousel
                              className="owl-theme headline-owl"
                              loop={false}
                              autoplay={true}
                              autoplayTimeout={1520}
                              smartSpeed={1500}
                              margin={20}
                              autoWidth={true}
                              responsive={headlineSlider.headlineSlider}
                              nav={false}
                              dots={false}
                        >
                              <div class="item">
                                    <Index.Box className="headline-card">
                                          <img src={PagesIndex.Svg.greenLines} className="headline-img" alt="logo" />
                                          <Index.Typography className='headline-title'>Easy live chat</Index.Typography>
                                    </Index.Box>
                              </div>
                              <div class="item">
                                    <Index.Box className="headline-card">
                                          <img src={PagesIndex.Svg.whiteLine} className="headline-img" alt="logo" />
                                          <Index.Typography className='headline-title'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</Index.Typography>
                                    </Index.Box>
                              </div>
                              <div class="item">
                                    <Index.Box className="headline-card">
                                          <img src={PagesIndex.Svg.greenLines} className="headline-img" alt="logo" />
                                          <Index.Typography className='headline-title'>Lorem ipsum dolor sit </Index.Typography>
                                    </Index.Box>
                              </div>
                              <div class="item">
                                    <Index.Box className="headline-card">
                                          <img src={PagesIndex.Svg.whiteLine} className="headline-img" alt="logo" />
                                          <Index.Typography className='headline-title'>Lorem ipsum dolor sit</Index.Typography>
                                    </Index.Box>
                              </div>
                              <div class="item">
                                    <Index.Box className="headline-card">
                                          <img src={PagesIndex.Svg.greenLines} className="headline-img" alt="logo" />
                                          <Index.Typography className='headline-title'>Lorem ipsum dolor sit amet, conse</Index.Typography>
                                    </Index.Box>
                              </div>
                              <div class="item">
                                    <Index.Box className="headline-card">
                                          <img src={PagesIndex.Svg.whiteLine} className="headline-img" alt="logo" />
                                          <Index.Typography className='headline-title'>Lorem ipsum dolor</Index.Typography>
                                    </Index.Box>
                              </div>
                              <div class="item">
                                    <Index.Box className="headline-card">
                                          <img src={PagesIndex.Svg.greenLines} className="headline-img" alt="logo" />
                                          <Index.Typography className='headline-title'>Lorem ipsum dolor sit amet, con</Index.Typography>
                                    </Index.Box>
                              </div>
                              <div class="item">
                                    <Index.Box className="headline-card">
                                          <img src={PagesIndex.Svg.whiteLine} className="headline-img" alt="logo" />
                                          <Index.Typography className='headline-title'>Easy live chat</Index.Typography>
                                    </Index.Box>
                              </div>
                        </Index.OwlCarousel> */}
                  </Index.Box>
            </>
      )
}
