import React, { useState } from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function HeroSection() {
  const [message, setMessage] = useState("Copy");
  return (
    <>
      <Index.Box className="hero-section">
        <Index.Box className="container">
          <Index.Box className='grid-row'>
            <Index.Box sx={{ width: 1 }} className="grid-main">
              <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column order1">
                  <Index.Box className="hero-col">
                    <Index.Box className="hero-content-main">
                      <Index.Typography className='hero-para'>AI-Driven Trading for a New Era
                      </Index.Typography>
                      <Index.Typography className='hero-title'>
                        Glorion:
                        <br />
                        <span className='hero-highlight-title'>
                          Redefining Trading with AI-Powered,
                        </span>
                      </Index.Typography>
                      <Index.Typography className='hero-sub-title'>Decentralized Solutions.</Index.Typography>
                      <Index.Box className='hero-btn-main'>
                        <Index.Button className='hero-btn'>Token ADDRESS</Index.Button>
                      </Index.Box>
                      <Index.Box className="hero-copy-main">
                        <Index.Typography className='hero-copy-text'>HhJpBhRRng56VsyLuTDL5BvHkXqsrahT</Index.Typography>
                        <Index.Box className="hero-copy-btn-main">
                          <Index.Tooltip
                            title={message}
                            arrow
                            placement="bottom"
                            className="admin-tooltip"
                          >
                            <Index.Button className='hero-copy-btn'
                              onMouseLeave={() => {
                                setTimeout(() => {
                                  setMessage("Copy");
                                }, 1000);
                              }}
                              onClick={() => {
                                setMessage("Copied ✓");
                                navigator.clipboard.writeText("HhJpBhRRng56VsyLuTDL5BvHkXqsrahT");
                              }}
                            >
                              <img src={PagesIndex.Svg.copyIcon} className='hero-copy-icon' alt='Copy' />
                            </Index.Button>
                          </Index.Tooltip>
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
                <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column order2">
                  <Index.Box className="hero-col">
                    <Index.Box className="hero-coin-img-main">
                      <img src={PagesIndex.Svg.heroCoin} className='hero-coin-img' alt='coin' />
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}
