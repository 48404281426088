import React, { useCallback, useEffect, useState } from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'

export default function Roadmap() {

  const [roadmapSlider, setroadmapSlider] = useState({
    roadmapSlider: {
      0: {
        items: 1,
        margin: 10,
      },
      768: {
        items: 2,
        margin: 20,
      },
      1024: {
        items: 2,
        margin: 30,
      },
      1200: {
        items: 4,
      },
      1600: {
        items: 5,
      }
    }
  })

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleGetData = useCallback(() => {
    PagesIndex.apiGetHandler(PagesIndex.Api.GET_ROADMAP).then((res) => {
      setLoading(false);
      if (res.status === 200) {
        setData(res?.data);
      }
    });
  }, []);

  useEffect(() => {
    handleGetData();
  }, [handleGetData])
  return (
    <>
      <Index.Box className="roadmap-section">
        <Index.Box className="container">
          <Index.Box className="section-title-main">
            <Index.motion.div
              animate={{ y: 250, opacity: 0, }}
              transition={{ duration: 0.8, delay: 0.1 }}
              whileInView={{ y: 0, opacity: 1, }}>
              <Index.Typography className="section-title section-title-roadmap">
                Our Roadmap
              </Index.Typography>
            </Index.motion.div>
            <Index.Typography className="section-title-para section-title-para-black">
              Glorion's roadmap outlines key milestones, driving innovation through AI-powered platforms while empowering the Solana community at every stage.
            </Index.Typography>
          </Index.Box>

          <Index.Box className="stepper-main">
            <Index.Box className="stepper-wrapper">
              <Index.List className="stepper-ul">
                <Index.ListItem className="stepper-li active"></Index.ListItem>
                <Index.ListItem className="stepper-li active"></Index.ListItem>
                <Index.ListItem className="stepper-li active"></Index.ListItem>
                <Index.ListItem className="stepper-li active"></Index.ListItem>
                <Index.ListItem className="stepper-li active"></Index.ListItem>
              </Index.List>
            </Index.Box>
          </Index.Box>
          <Index.Box className="roadmap-row">
            {data?.length ? (
              <Index.OwlCarousel
                className="owl-theme roadmap-owl"
                loop
                margin={40}
                responsive={roadmapSlider.roadmapSlider}
                nav={true}
                autoplay={true}
                autoplayTimeout={2000} // 2 seconds delay between transitions
                smartSpeed={1000} // Smooth transition speed of 1 second
                dots={false}
                stagePadding={0}
                navText={["<img src=" + PagesIndex.Svg.prevArrow + " >", "<img src=" + PagesIndex.Svg.nextArrow + " >"]}
              >
                {
                  data?.map((ele) => {
                    return (
                      <Index.Box className="roadmap-card" key={ele?._id}>
                        <Index.Typography className='roadmap-title'>{ele?.title}</Index.Typography>
                        <Index.Typography
                          className='roadmap-para'
                          dangerouslySetInnerHTML={{ __html: ele?.content }}
                        />
                      </Index.Box>
                    )
                  })
                }
              </Index.OwlCarousel>
            ) : null}
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}
