import * as Yup from "yup";

const fullNameRegex = /^(?=[a-zA-Z])[a-zA-Z]+([',.\- ][a-zA-Z]?[a-zA-Z]*)*$/;
const emailRegex =
  /\b^[a-zA-Z0-9.]+@[A-Za-z0-9-]+\.[A-Za-z]{2,3}(?:\.[A-Za-z]{2,3})?\b$/;
// Dispossable email addresses not allowed ↓
// /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(((?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9]))(?<!mailinator\.com)(?<!trbvm\.com)(?<!guerrillamail\.com)(?<!guerrillamailblock\.com)(?<!sharklasers\.com)(?<!guerrillamail\.net)(?<!guerrillamail\.org)(?<!guerrillamail\.biz)(?<!spam4\.me)(?<!grr\.la)(?<!guerrillamail\.de)(?<!grandmasmail\.com)(?<!zetmail\.com)(?<!vomoto\.com)(?<!abyssmail\.com)(?<!anappthat\.com)(?<!eelmail\.com)(?<!yopmail\.com)(?<!fakeinbox\.com))$/;
const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%,*?&~`#()[\]{}<>^/\\|+=_-]).{8,}$/;
const otpRegex = /^\d{4}$/;
const tagRegex = /^[a-zA-Z0-9_&-]+$/;
const nameWithSpaceAndSpecialCaracterAnd = /^(?! )(?!.*\s{2})[A-Za-z& ]*$/;
const companyNameRagex =
  /^(?=.*[a-zA-Z])(?=.*[a-zA-Z0-9])[\p{L}\p{M}\p{N}\p{P}\p{S} !@#$%^&*()_+\-=,.<>?;:'"{}|\\/~`]+$/u;
const designationNameRagex =
  /^(?! )(?=.*[A-Za-z])(?!\d+$)(?![^A-Za-z0-9_().]+$)[-A-Za-z0-9_().]+(?:\s[-A-Za-z0-9_().]+)*(?<! )$/;
const firstLastNameRegex = /^[A-Za-z]+(?: [A-Za-z]+)*$/;
const getCharacterValidationError = (str) => {
  return `Your password must have at least 1 ${str} character`;
};
const valueValidationError = (fieldName, str) => {
  return `${fieldName} can not contain ${str}`;
};

export const contactUsValidationSchema = Yup.object({
  name: Yup.string()
    .required("Please enter name")
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Name", "more than one space between two words")
    )
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Name", "spaces at start and end")
    )
    .matches(
      fullNameRegex,
      valueValidationError("Name", "numbers and special characters")
    ),
  email: Yup.string()
    .test("no-starting-dot", "Please enter a valid email", (value) => {
      if (!value) return false;
      return !/^\./.test(value);
    })
    .test("no-ending-dot", "Please enter a valid email", (value) => {
      if (!value) return false;
      const username = value.split("@")[0];
      return !/\.$/.test(username);
    })
    .test("min-username-length", "Please enter a valid email", (value) => {
      if (!value) return false;
      const username = value.split("@")[0];
      return username.length >= 6;
    })
    .test(
      "consecutive-dots",
      "Please enter a valid email",
      (value) => !/\.{2,}/.test(value || "")
    )
    .matches(emailRegex, "Please enter a valid email")
    .required("Please enter email"),
  phone: Yup.string()
    .required("Please enter phone")
    .matches(/^(?!([0-9])\1+$)[1-9][0-9]{9,14}$/, "Please enter valid phone"),
  question: Yup.string().required("Please select question"),
  reason: Yup.string()
    .required("Please enter reason for contact")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Reason for contact", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Reason for contact", "more than one space between two words")
    ),
  message: Yup.string()
    .required("Please enter message")
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Message", "spaces at start and end")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Message", "more than one space between two words")
    ),
});
