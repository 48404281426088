import React, { useState } from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex';

export default function ContactUs() {
      const navigate = PagesIndex.useNavigate();
      const [age, setAge] = React.useState('');

      const handleChangedropdown = (event) => {
            setAge(event.target.value);
      };

      const [buttonSpinner, setButtonSpinner] = useState(false);
      const initialValues = {
            name: "",
            email: "",
            phone: "",
            reason: "",
            message: "",
            question: "social_media",
      };

      const handleSubmit = async (values, { resetForm }) => {
            const urlencoded = new URLSearchParams();
            urlencoded.append("name", values?.name);
            urlencoded.append("email", values?.email);
            urlencoded.append("phone", values?.phone);
            urlencoded.append("reason", values?.reason);
            urlencoded.append("message", values?.message);
            urlencoded.append("question", values?.question);
            setButtonSpinner(true);
            try {
                  const res = await PagesIndex.apiPostHandler(
                        PagesIndex.Api.ADD_CONTACT_US,
                        urlencoded
                  );
                  setButtonSpinner(false);
                  if (res.status === 200 || res.status === 201) {
                        PagesIndex.toasterSuccess(res?.message);
                        resetForm();
                        navigate("/")
                  } else {
                        PagesIndex.toasterError(res?.message);
                        setButtonSpinner(false);
                  }
            } catch (error) {
                  console.log(error);
            }
      };

      return (
            <PagesIndex.Formik
                  enableReinitialize
                  onSubmit={handleSubmit}
                  initialValues={initialValues}
                  validationSchema={PagesIndex.contactUsValidationSchema}
            >
                  {({ values, errors, touched, handleChange, handleSubmit }) => (
                        <PagesIndex.Form onSubmit={handleSubmit}>
                              <Index.Box className="inner-page-banner-main blog-details-bg">
                                    <Index.Box className="container">
                                          <Index.Box className="inner-page-banner-content">
                                                <Index.Typography className='inner-page-heading'>
                                                      Contact Us
                                                </Index.Typography>
                                                <Index.Box className='inner-page-breadcrumb'>
                                                      <Index.Typography className='inner-page-breadcrumb-link'>
                                                            Home
                                                      </Index.Typography>
                                                      <Index.Typography className='inner-page-breadcrumb-link inner-page-breadcrumb-link-gap'>
                                                            /
                                                      </Index.Typography>
                                                      <Index.Typography className='inner-page-breadcrumb-link'>
                                                            Contact Us
                                                      </Index.Typography>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                              <Index.Box className="inner-page-main">
                                    <Index.Box className="contact-section">
                                          <Index.Box className="container">
                                                <Index.Box className='contact-row'>
                                                      <Index.Box sx={{ width: 1 }} className="grid-main">
                                                            <Index.Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                                                                        <Index.Box className="user-input-box contact-input-box">
                                                                              <Index.FormHelperText className='user-form-lable'>Name</Index.FormHelperText>
                                                                              <Index.Box className="user-form-group">
                                                                                    <Index.TextField
                                                                                          fullWidth
                                                                                          id="fullWidth"
                                                                                          className="user-form-control"
                                                                                          placeholder="Enter Name"
                                                                                          onChange={handleChange}
                                                                                          name="name"
                                                                                          value={values.name}
                                                                                          error={
                                                                                                errors?.name && touched?.name ? true : false
                                                                                          }
                                                                                          inputProps={{ maxLength: 30 }}
                                                                                          InputLabelProps={{ shrink: !!values.name }}
                                                                                    />
                                                                                    <Index.FormHelperText className="error-text">
                                                                                          {errors?.name && touched?.name
                                                                                                ? errors?.name
                                                                                                : null}
                                                                                    </Index.FormHelperText>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                                                                        <Index.Box className="user-input-box contact-input-box">
                                                                              <Index.FormHelperText className='user-form-lable'>Email</Index.FormHelperText>
                                                                              <Index.Box className="user-form-group">
                                                                                    <Index.TextField
                                                                                          fullWidth
                                                                                          id="fullWidth"
                                                                                          className="user-form-control"
                                                                                          placeholder="Enter Email"
                                                                                          onChange={handleChange}
                                                                                          name="email"
                                                                                          value={values.email}
                                                                                          // error={errors?.email && touched?.email ? true : false}
                                                                                          inputProps={{ maxLength: 50 }}
                                                                                          InputLabelProps={{ shrink: !!values.email }}
                                                                                    />
                                                                                    <Index.FormHelperText className="error-text">
                                                                                          {errors?.email && touched?.email
                                                                                                ? errors?.email
                                                                                                : null}
                                                                                    </Index.FormHelperText>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                                                                        <Index.Box className="user-input-box contact-input-box">
                                                                              <Index.FormHelperText className='user-form-lable'>Phone</Index.FormHelperText>
                                                                              <Index.Box className="user-form-group">
                                                                                    <Index.TextField
                                                                                          fullWidth
                                                                                          id="fullWidth"
                                                                                          className="user-form-control"
                                                                                          placeholder="Enter Phone"
                                                                                          onChange={(e) => {
                                                                                                const regex = /^[0-9]*$/;
                                                                                                if (regex.test(e.target.value)) {
                                                                                                      handleChange(e);
                                                                                                }
                                                                                          }}
                                                                                          name="phone"
                                                                                          value={values.phone}
                                                                                          error={!!(errors?.phone && touched?.phone)}
                                                                                          inputProps={{ maxLength: 10 }}
                                                                                          InputLabelProps={{
                                                                                                shrink: !!values.phone,
                                                                                          }}
                                                                                    />
                                                                                    <Index.FormHelperText className="error-text">
                                                                                          {errors?.phone && touched?.phone
                                                                                                ? errors?.phone
                                                                                                : null}
                                                                                    </Index.FormHelperText>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                                                                        <Index.Box className="user-input-box contact-input-box">
                                                                              <Index.FormHelperText className='user-form-lable'>Reason for contact</Index.FormHelperText>
                                                                              <Index.Box className="user-form-group">
                                                                                    <Index.TextField
                                                                                          fullWidth
                                                                                          id="fullWidth"
                                                                                          className="user-form-control"
                                                                                          onChange={handleChange}
                                                                                          placeholder="Enter Reason for contact"
                                                                                          name="reason"
                                                                                          value={values.reason}
                                                                                          error={
                                                                                                errors?.reason && touched?.reason
                                                                                                      ? true
                                                                                                      : false
                                                                                          }
                                                                                          inputProps={{ maxLength: 30 }}
                                                                                          InputLabelProps={{ shrink: !!values.reason }}
                                                                                    />
                                                                                    <Index.FormHelperText className="error-text">
                                                                                          {errors?.reason && touched?.reason
                                                                                                ? errors?.reason
                                                                                                : null}
                                                                                    </Index.FormHelperText>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 12", md: "span 12", lg: "span 12" }} className="grid-column">
                                                                        <Index.Box className="user-input-box contact-input-box">
                                                                              <Index.FormHelperText className='user-form-lable'>Message</Index.FormHelperText>
                                                                              <Index.Box className="user-form-group">
                                                                                    <Index.TextareaAutosize
                                                                                          aria-label="minimum height"
                                                                                          // minRows={3}
                                                                                          className="user-form-control-textarea user-form-control"
                                                                                          placeholder="Enter Message"
                                                                                          onChange={handleChange}
                                                                                          name="message"
                                                                                          value={values.message}
                                                                                    />
                                                                                    <Index.FormHelperText className="error-text">
                                                                                          {errors?.message && touched?.message
                                                                                                ? errors?.message
                                                                                                : null}
                                                                                    </Index.FormHelperText>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                                  <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column">
                                                                        <Index.Box className="user-input-box contact-input-box">
                                                                              <Index.FormHelperText className='user-form-lable'>How did you hear about us?</Index.FormHelperText>
                                                                              <Index.Box className="user-form-group">
                                                                                    <Index.Box className='user-dropdown-box'>
                                                                                          <Index.FormControl className='user-form-control'>
                                                                                                <Index.Select className='user-dropdown-select'
                                                                                                      displayEmpty
                                                                                                      inputProps={{ 'aria-label': 'Without label' }}
                                                                                                      value={values.question}
                                                                                                      name="question"
                                                                                                      onChange={handleChange}
                                                                                                      renderValue={
                                                                                                            values?.question ? undefined : () => ""
                                                                                                      }
                                                                                                >
                                                                                                      <Index.MenuItem
                                                                                                            value={"social_media"}
                                                                                                            className='menuitem'>
                                                                                                            Social media
                                                                                                      </Index.MenuItem>
                                                                                                      <Index.MenuItem
                                                                                                            value={"news"}
                                                                                                            className='menuitem'>News</Index.MenuItem>
                                                                                                      <Index.MenuItem
                                                                                                            value={"advertising"}
                                                                                                            className='menuitem'>Advertising</Index.MenuItem>
                                                                                                </Index.Select>
                                                                                          </Index.FormControl>
                                                                                          <Index.FormHelperText className="error-text">
                                                                                                {errors?.question && touched?.question
                                                                                                      ? errors?.question
                                                                                                      : null}
                                                                                          </Index.FormHelperText>
                                                                                    </Index.Box>
                                                                              </Index.Box>
                                                                        </Index.Box>
                                                                  </Index.Box>
                                                            </Index.Box>
                                                      </Index.Box>
                                                </Index.Box>
                                                <Index.Box className="user-btn-flex primary-btn-main">
                                                      <Index.Button className='primary-btn contact-submit-btn'
                                                            disabled={buttonSpinner}
                                                            type="submit"
                                                      >Submit</Index.Button>
                                                </Index.Box>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box >
                        </PagesIndex.Form>
                  )}
            </PagesIndex.Formik>
      )
}
