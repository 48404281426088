import React, { useEffect } from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'
import { useLocation, useParams } from 'react-router-dom'


export default function Home() {
  const location = useLocation();
  let targetId = location.hash ? location.hash.substring(1) : ""; // Strip the '#' symbol

  useEffect(() => {
    if (targetId) {
      const elementToScroll = document.getElementById(targetId);
      if (elementToScroll) {
        elementToScroll.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [targetId]);
  return (
    <>
      <Index.Box className="home-hero-main">
        <PagesIndex.HeroSection />
      </Index.Box>
      <Index.Box className="headline-main">
        <PagesIndex.HeadlineSlider />
      </Index.Box>
      <Index.Box className="why-choose-main" id="why-choose-main">
        <PagesIndex.WhyChoose />
      </Index.Box>
      <Index.Box className="tokenomics-main" id="tokenomics-main">
        <PagesIndex.Tokenomics />
      </Index.Box>
      <Index.Box className="roadmap-main" id="roadmap-main">
        <PagesIndex.Roadmap />
      </Index.Box>
      <Index.Box className="section-wrapper">
        {/* <Index.Box className="team-main"  id="team-main">
          <PagesIndex.Team />
        </Index.Box> */}
        <Index.Box className="faq-main" id="faq-main">
          <PagesIndex.Faq />
        </Index.Box>
      </Index.Box>
    </>
  )
}
