
import Svg from '../assets/Svg'
import Png from "../assets/Png";
import PrimaryButton from '../component/common/Button/PrimaryButton';
import BorderButton from '../component/common/Button/BorderButton';
import HeroSection from './user/pages/home/HeroSection'
import Header from "../component/user/defaulLayout/Header";
import Footer from "../component/user/defaulLayout/Footer";
import WhyChoose from './user/pages/home/WhyChoose';
import Tokenomics from './user/pages/home/Tokenomics';
import Roadmap from './user/pages/home/Roadmap';
import Team from './user/pages/home/Team';
import Faq from './user/pages/home/Faq';
import { Api } from '../component/config/Api';
import { apiGetHandler, apiPostHandler } from '../component/config/ApiHandler';
import {
      Formik,
      Field,
      Form,
      ErrorMessage,
      useFormikContext,
      FieldArray,
} from "formik";
import { toasterSuccess, toasterError, toasterWarning, toasterInfo } from '../utils/toaster/Toaster';
import HeadlineSlider from './user/pages/home/HeadlineSlider';
import BlogCategory from './user/pages/blog/BlogCategory';
import BlogRecentPost from './user/pages/blog/BlogRecentPost';
import BlogArticles from './user/pages/blog/BlogArticles';
import Loader from '../component/common/loader/Loader';
import { contactUsValidationSchema } from '../validation/FormikValidation';
import { useNavigate } from 'react-router-dom';
export default {
      Svg,
      Png,
      Header,
      useNavigate,
      Footer,
      PrimaryButton,
      BorderButton,
      HeroSection,
      WhyChoose,
      Tokenomics,
      Loader,
      Roadmap,
      Team,
      Faq,
      HeadlineSlider,
      apiGetHandler,
      apiPostHandler,
      Formik,
      Field,
      ErrorMessage,
      FieldArray,
      Form,
      useFormikContext,
      Api,
      toasterSuccess,
      toasterError,
      toasterWarning,
      toasterInfo,
      BlogCategory,
      BlogRecentPost,
      contactUsValidationSchema,
};
