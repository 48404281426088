import React from 'react'
import Index from '../../../container/Index'
import PagesIndex from '../../../container/PagesIndex'
import * as Yup from "yup";

const emailRegex =
  /\b^[a-zA-Z0-9.]+@[A-Za-z0-9-]+\.[A-Za-z]{2,3}(?:\.[A-Za-z]{2,3})?\b$/;
const valueValidationError = (fieldName, str) => {
  return `${fieldName} can not contain ${str}`;
};

const emailValidationSchema = Yup.object({
  email: Yup.string()
    .test(
      "no-starting-dot",
      "Please enter a valid email address",
      (value) => {
        if (!value) return false;
        return !/^\./.test(value);
      }
    )
    .test(
      "no-ending-dot",
      "Please enter a valid email address",
      (value) => {
        if (!value) return false;
        const username = value.split("@")[0];
        return !/\.$/.test(username);
      }
    )
    .test(
      "consecutive-dots",
      "Please enter a valid email address",
      (value) => !/\.{2,}/.test(value || "")
    )
    .matches(emailRegex, "Please enter a valid email address")
    .required("Please enter email address"),
});

export default function Footer() {
  const initialValues = {
    email: "",
  };

  const handleAddEmail = (values, { resetForm }) => {
    const urlEncoded = new URLSearchParams();
    urlEncoded.append("email", values?.email);
    PagesIndex.apiPostHandler(PagesIndex.Api.ADD_SUBSCRIPTION, urlEncoded)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          // PagesIndex.toasterSuccess(res?.message);
          PagesIndex.toasterSuccess("Thank you for subscribing");
          resetForm();
        } else {
          PagesIndex.toasterError(res?.message);
        }
      })
      .catch((err) => {
        PagesIndex.toasterError(err?.response?.message);
      });
  }
  return (
    <PagesIndex.Formik
      enableReinitialize
      onSubmit={handleAddEmail}
      initialValues={initialValues}
      validationSchema={emailValidationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} noValidate="novalidate">
          <Index.Box className="footer-bg-main">
            <Index.Box className="footer">
              <Index.Box className="container">
                <Index.Box className="footer-content-main">
                  <Index.motion.div
                    animate={{ y: 250, opacity: 0, }}
                    transition={{ duration: 0.8, delay: 0.1 }}
                    whileInView={{ y: 0, opacity: 1, }}>
                    <Index.Typography className='footer-title'>Don't Miss Out</Index.Typography>
                  </Index.motion.div>
                  <Index.Box className="footer-input-box primary-btn-main">
                    <Index.Box className="form-group">
                      <Index.TextField
                        className='footer-input'
                        placeholder='Email Address'
                        onChange={handleChange}
                        value={values.email}
                        name="email"
                        error={errors?.email && touched?.email}
                        inputProps={{ maxLength: 50 }}
                      />
                      {errors?.email && touched?.email && <Index.FormHelperText className="user-error-text footer-error-text">
                        {errors?.email && touched?.email ? errors?.email : null}
                      </Index.FormHelperText>}
                    </Index.Box>
                    <Index.Box>
                      <Index.Button className='footer-btn primary-btn' type="submit">Subscribe</Index.Button>
                      {/* <Index.FormHelperText className="user-error-text footer-error-text"></Index.FormHelperText> */}
                    </Index.Box>
                  </Index.Box>
                  <Index.Typography className="footer-para">Stay ahead in the world of trading! Subscribe to receive the latest updates on market trends and exclusive insights from Glorion. </Index.Typography>
                  <Index.Box className="footer-social-main">
                    <Index.List className='footer-social-ul'>
                      <Index.ListItem className='footer-social-li'>
                        <a href='https://x.com/login' target='_blank' className='footer-social-link'>
                          <img src={PagesIndex.Svg.twitterIcon} className='footer-social-icon' alt='twitter' />
                        </a>
                      </Index.ListItem>
                      <Index.ListItem className='footer-social-li'>
                        <a href='https://www.linkedin.com' target='_blank' className='footer-social-link'>
                          <img src={PagesIndex.Svg.LinkdinIcon} className='footer-social-icon' alt='linkedin' />
                        </a>
                      </Index.ListItem>
                      <Index.ListItem className='footer-social-li'>
                        <a href='https://www.youtube.com' target='_blank' className='footer-social-link'>
                          <img src={PagesIndex.Svg.youtubeIcon} className='footer-social-icon' alt='Youtube' />
                        </a>
                      </Index.ListItem>
                    </Index.List>
                  </Index.Box>
                  <Index.Box className="footer-nav-main">
                    <Index.List className='footer-nav-list'>
                      <Index.ListItem className='footer-nav-li'>
                        <Index.Link to={'/cms/privacy-policy'} target='_blank' className='footer-nav-link'>
                          Privacy Policy
                        </Index.Link>
                      </Index.ListItem>
                      <Index.ListItem className='footer-nav-li'>
                        <Index.Link to={'cms/terms-and-conditions'} target='_blank' className='footer-nav-link'>
                          Terms & Conditions
                        </Index.Link>
                      </Index.ListItem>
                      {/* <Index.ListItem className='footer-nav-li'>
                        <Index.Link href='#cookies-policy' target='_blank' className='footer-nav-link'>
                          Cookies Policy
                        </Index.Link>
                      </Index.ListItem>
                      <Index.ListItem className='footer-nav-li'>
                        <Index.Link href='#cookies-settings' target='_blank' className='footer-nav-link'>
                          Cookies Settings
                        </Index.Link>
                      </Index.ListItem> */}
                    </Index.List>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
              <Index.Box className="container">
                <Index.Box className="footer-copy-right-box">
                  <Index.Typography className='footer-copy-right-text'>Copyright ©{new Date().getFullYear()}. All rights reserved.</Index.Typography>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </form>
      )}
    </PagesIndex.Formik>
  )
}
